import React from "react"
import PropTypes from "prop-types"
// import RenderAvatar from 'react-avatar'
import Dropzone from 'react-dropzone'
import request from 'superagent'

import {
  createAvatarComponent,
  ValueSource,
  SrcSource
} from "react-avatar";

const LightRenderAvatar = createAvatarComponent({
  sources: [ValueSource]
});

const RenderAvatar = createAvatarComponent({
  sources: [SrcSource]
});

class Avatar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {edit: false, uploading: false, avatarUrl: props.avatarUrl, isCurrentUser: false, appearance: 'offline'}
  }

  componentDidMount() {
    this.setupUser(this.props.uid);
  }

  setupUser(uid) {
    if (uid) {
      this.setState({isCurrentUser: uid == window.UID})
      this.hasAppearedId = App.appearanceChannel.hasAppeared(uid, function (status) {
        this.setState({appearance: status});
      }.bind(this));
    }
  }

  componentDidUpdate(prevProps) {
    const {avatarUrl, uid} = this.props;
    
    if (prevProps.avatarUrl != avatarUrl)
      this.setState({ avatarUrl: avatarUrl })
    
      if (prevProps.uid == undefined && uid)
      this.setupUser(uid)
  }

  componentWillUnmount() {
    if (this.props.uid) {
      App.appearanceChannel.offHasAppeared(this.props.uid, this.hasAppearedId)
    }
  }

  onDrop(files) {
    this.setState({edit: false, uploading: true});

    const req = request.post((this.props.update_url || '/users/avatar'));
    req.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)
    files.forEach((file) => {
        req.attach('file', file);
    });
    req.end((err, res) => {
      if (res.ok) {
        this.setState({avatarUrl: res.body.avatar_url, edit: false, uploading: false})
      } else {
        alert(err.message);
      }
    });
  }

  handleClick() {
    if (this.props.clickable != false) {
      Turbolinks.visit(`/${this.props.username}`)
    }
  }

  handleEditClick() {
    this.setState({edit: !this.state.edit, uploading: false})
  }

  render () {
    let avatar;

    if (this.state.edit) {
      avatar = (
        <Dropzone accept="image/*" onDrop={this.onDrop.bind(this)} multiple={false} style={{width: this.props.size, height: this.props.size, borderWidth: 1, borderColor: '#666', borderStyle: 'dashed', borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <p className="text-center mb-0 text-muted px-2" >Drop image, or click to select image</p>
        </Dropzone>
      );
    } else if (this.state.uploading) {
      avatar = (
        <Dropzone disabled={true} style={{width: this.props.size, height: this.props.size, borderWidth: 1, borderColor: '#666', borderStyle: 'dashed', borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <p className="text-center mb-0" >Uploading...</p>
        </Dropzone>
      );
    } else {
      if (this.state.avatarUrl && this.state.avatarUrl.length > 0) {
        avatar = <RenderAvatar name={this.props.name} size={this.props.size} src={this.state.avatarUrl} round={true} onClick={this.handleClick.bind(this)} style={{cursor: 'pointer'}}/>
      } else {
        avatar = <LightRenderAvatar name={this.props.name} size={this.props.size} round={true} onClick={this.handleClick.bind(this)} style={{cursor: 'pointer'}}/>
      }
    }

    let editButton;
    if (this.state.edit && this.props.editable) {
      editButton = <button onClick={this.handleEditClick.bind(this)} className="btn btn-link pt-2"><i className="fas fa-times-circle"></i></button>;
    } else if (this.props.editable) {
      editButton = <button onClick={this.handleEditClick.bind(this)} className="btn btn-primary btn-sm"><i className="fas fa-cog"></i></button>;
    }

    let className = ''
    if (this.state.isCurrentUser) {
      className = this.props.className + " profile-avatar-icon"
    } else {
      className = this.props.className + " profile-avatar-icon profile-avatar-icon-" + this.props.colour
    }

    let counter
    if (this.props.count && this.props.count > 0) {
      counter = <span className="badge badge-pill badge-danger avatar-counter">{this.props.count}</span>
    } else {
      if (this.state.appearance == 'online') {
        counter = <span className="badge badge-pill badge-success avatar-appearance" title="Online!"></span>
      } else if (this.state.appearance == 'away') {
        counter = <span className="badge badge-pill badge-warning avatar-appearance" title="Away!"></span>
      } else {
        counter = null;
      }
    }


    return (
      <div className={className}>
        {avatar}
        {editButton}
        {counter}
      </div>
    );
  }
}

Avatar.propTypes = {
  editable: PropTypes.bool,
  username: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  size: PropTypes.number,
  avatarUrl: PropTypes.string
};

export default Avatar
