import React from "react"
import PropTypes from "prop-types"
import request from 'superagent'
import Dropzone from 'react-dropzone'
import Avatar from './Avatar'
import ContentEditable from './ContentEditable'
import PostTypes from './PostTypes'
import PollForm from "./PollForm";
import'./Post.scss'

class PostForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {focused: !!props.edit, type: (props.type || "light"), body: (props.body || ""), title: (props.title || ""), imageId: (props.image_id || ""), imageUrl: (props.image_url || ""), loading: false }
  }

  onDrop(files) {
    this.setState({loading: true, dropzoneActive: false});

    const req = request.post('/upload');
    req.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)
    files.forEach((file) => {
        req.attach('files[]', file);
    });
    req.end((err, res) => {
      let fileDetails = res.body.files[0];
      this.setState({imageId: fileDetails.id, imageUrl: fileDetails.url, loading: false})
    });
  }

  onDragEnter() {
    this.setState({dropzoneActive: true});
  }

  onDragLeave() {
    this.setState({dropzoneActive: false});
  }

  handleFocus() {
    this.setState({focused: true});
  }

  handleClick() {
    this.setState({loading: true});
    const { body, title, type, imageId, options } = this.state

    const req = request(this.props.method, this.props.url);
    req.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)
    req.send({ body, title, type, image_id: imageId, options })

    req.end((err, res) => {
      if (res.ok) {
        if (this.props.edit) {
          this.setState({loading: false});
          Turbolinks.visit(`/posts/${this.props.id}`);
        } else {
          Turbolinks.visit("/");
        }
      } else {
        alert(err.message);
      }
    });
  }

  handleTitleChange(e, value) {
    this.setState({title: value});
  }

  handleBodyChange(e, value) {
    this.setState({body: value});
  }

  handleTypeChange(type) {
    this.setState({type: type});
  }

  handleOptionsChange = (options) => this.setState({ options })

  renderPoll () {
    return <PollForm updatePost={this.handleOptionsChange} />
  }

  renderContentEditable() {
    let className = (this.state.focused ? "pl-0 pr-2 py-2" : " p-4 m-0 bg-light");
    let tagName = "p";
    switch (this.state.type) {
      case "primary":
      case "secondary":
      case "tertiary":
      case "info":
      case "success":
      case "danger":
      case "dark":
      case "medium":
        className = "text-center px-2 py-4 text-white";
        tagName = "h3";
        break;
      case "warning":
        className = "text-center px-2 py-4 text-dark";
        tagName = "h3";
        break;
    }
    return <ContentEditable html={this.state.body} className={className} tagName={tagName} onFocus={this.handleFocus.bind(this)} onChange={this.handleBodyChange.bind(this)} placeholder="What's new?" contentEditable='plaintext-only'/>;
  }

  postTitleContentEditable (required = false) {
    return <ContentEditable
      html={this.state.title}
      tagName='h3'
      onChange={this.handleTitleChange.bind(this)}
      placeholder={`Title ${required ? '(Required)' : '(Optional)'}`}
      contentEditable='plaintext-only'
    />
  }

  componentDidMount() {
    this.setState({uid: window.UID, username: window.USERNAME, name: window.NAME, email: window.EMAIL, avatarUrl: window.AVATAR_URL})
  }

  render () {
    let titleInput;
    let actionBar;
    let imageInput;
    let image;

    if (this.state.focused) {

      switch (this.state.type) {
        case 'light':
          imageInput = <p className="text-center mb-0 text-muted cursor-pointer" onClick={() => { this.dropzoneRef.open() }}><small>Try dropping some files, or click here to select files to upload.</small></p>;
          titleInput = this.postTitleContentEditable()
          break;
        case 'poll':
          imageInput = null
          titleInput = this.postTitleContentEditable(true)
          break;
      
        default:
          imageInput = null;
          titleInput = null;
          break;
      }

      actionBar = (
        <div className="d-flex justify-content-between align-items-center mt-3">
          <PostTypes type={this.state.type} onChange={this.handleTypeChange.bind(this)} />
          <button className="btn btn-primary" disabled={!this.state.options && this.state.body.trim().length <= 0} onClick={this.handleClick.bind(this)}>{this.props.edit ? 'Edit Post' : 'Create Post'}</button>
        </div>
      )
    }

    let cardBody;

    if (this.state.loading) {
      cardBody = (
        <div className="card-body text-center p-5">
          <i className="fas fa-spinner-third fa-3x post-saving-spin"></i>
        </div>
      );
    } else {
      cardBody = (
        <div className={"card-body bg-" + this.state.type + (this.state.focused ? '' : ' not-focused')}>
          {imageInput}
          {titleInput}
          {this.state.type === 'poll' ? this.renderPoll() : this.renderContentEditable()}
          {actionBar}
        </div>
      );
    }

    if (!this.state.loading && this.state.type == 'light' && this.state.imageUrl) {
      image = <img src={this.state.imageUrl} className="img-fluid"/>;
    }

    const overlayStyle = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0,0,0,0.5)',
      color: '#fff',
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    };
    return (
      <div id="post-create" className="card mb-3">
        <div className="card-header d-flex align-items-center justify-content-between py-2 px-3">
          <div className="card-header-user d-flex justify-content-start align-items-center">
            <Avatar uid={this.state.uid} username={this.state.username} name={this.state.name} size={30} email={this.state.email} avatarUrl={this.state.avatarUrl} />
            <a className="ml-2 h5 mb-0">
              {this.state.name + " "}
            </a>
          </div>
        </div>
        <Dropzone ref={(node) => { this.dropzoneRef = node; }} disableClick accept="image/*" onDrop={this.onDrop.bind(this)} onDragEnter={this.onDragEnter.bind(this)} onDragLeave={this.onDragLeave.bind(this)} multiple={false} style={{position: "relative"}} >
          { this.state.dropzoneActive && <div style={overlayStyle}><i className="fal fa-cloud-upload fa-3x mr-2"></i> Drop files here...</div> }
          {image}
          {cardBody}
        </Dropzone>
      </div>
    );
  }
}

PostForm.propTypes = {
  username: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  avatarUrl: PropTypes.string
};

export default PostForm
