import React from "react"
import request from 'superagent'

var linkify = require("linkifyjs")
import hashtag from "linkifyjs/plugins/hashtag"
import mention from "linkifyjs/plugins/mention"
import Linkify from "linkifyjs/react"
hashtag(linkify)
mention(linkify)

import Avatar from '../Avatar'
import CommentForm from './CommentForm'
import Like from '../Like'
import UrlPreview from '../UrlPreview'

class Comment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {edit: false, body: props.body, canEdit: false}
  }

  componentDidMount() {
    this.setState({canEdit: (this.props.uid == window.UID)})
  }

  toggleEdit() {
    this.setState({edit: !this.state.edit})
  }

  submitForm(body) {
    const req = request.patch(`/posts/${this.props.commentable_id}/comments/${this.props.id}.json`);
    req.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)
    req.set('accept', 'json')
    req.send({ body: body })
    req.end((err, res) => {
      if (res.ok) {
        this.setState({body: res.body.body, edit: false});
      } else {
        alert(err.message);
      }
    });
  }

  renderPreview(url, index) {
    return <UrlPreview key={index} url={url} />;
  }

  render () {
    let editButton;

    if (this.state.canEdit) {
      editButton = <button onClick={this.toggleEdit.bind(this)} className="btn btn-link btn-sm float-right p-0 mr-1">Edit</button>;
    }

    if (this.state.edit) {
      return <CommentForm {...this.props} body={this.state.body} submitForm={this.submitForm.bind(this)} />;
    } else {
      let linkOptions = {formatHref: function (href, type) {
        if (type === 'hashtag') {
          href = '/posts/tag/' + href.substring(1);
        }

        if (type === 'mention') {
          href = '/' + href.substring(1);
        }

        return href;
      }};

      return (
        <div id={this.props.id} className="comment media my-1">
          <Avatar username={this.props.username} className="mr-2 mt-1" name={this.props.name} size={30} uid={this.props.uid} email={this.props.email} avatarUrl={this.props.avatarUrl} colour={this.props.colour} />
          <div className="media-body">
            <p className="rounded bg-light text-dark py-2 px-3 mb-2">
              <Like like_url={`/posts/${this.props.commentable_id}/comments/${this.props.id}/like`} unlike_url={`/posts/${this.props.commentable_id}/comments/${this.props.id}/unlike`} likes={this.props.like_user_ids} />
              {editButton}
              <Linkify tagName="span" options={linkOptions}>{this.state.body}</Linkify>
            </p>
            {this.props.urls.map(function (url, index) {
              return this.renderPreview(url, index);
            }.bind(this))}
            {/*<CommentList comments={this.props.comments} />*/}
          </div>
        </div>
      );
    }


  }
}

export default Comment
