import React from "react"
import { CirclePicker } from 'react-color';

const HEX_TO_COLOUR = {
  "#3880ff" : "primary",
  "#0cd1e8" : "secondary",
  "#633ce0" : "tertiary",
  "#10dc60" : "success",
  "#ffce00" : "warning",
  "#f04141" : "danger",
  "#222428" : "dark",
  "#989aa2" : "medium",
  "#f4f5f8" : "light"
}

const COLOUR_TO_HEX = {
  primary   : "#3880ff",
  secondary : "#0cd1e8",
  tertiary  : "#633ce0",
  success   : "#10dc60",
  warning   : "#ffce00",
  danger    : "#f04141",
  dark      : "#222428",
  medium    : "#989aa2",
  light     : "#f4f5f8"
}

class ColourPicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: props.value}
  }

  componentDidMount(prevProps, prevState) {
    $("body").addClass(`profile-colour-${this.state.value}`)
  }

  componentDidUpdate(prevProps, prevState) {
    $("body").removeClass(`profile-colour-${prevState.value}`).addClass(`profile-colour-${this.state.value}`)
  }

  handleChange(color, event) {
    this.setState({value: HEX_TO_COLOUR[color.hex]});
  }

  render() {
    return (
      <div className="colour-picker">
        <input type='hidden' id={this.props.id} name={this.props.field} value={this.state.value} className="form-control" onChange={() => {}}/>
        <CirclePicker colors={Object.keys(HEX_TO_COLOUR)} color={COLOUR_TO_HEX[this.state.value]} onChangeComplete={ this.handleChange.bind(this)} />
      </div>
    );
  }

}

export default ColourPicker
