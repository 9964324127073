import React from "react"
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import en from 'javascript-time-ago/locale/en'
import 'react-time-ago/Tooltip.css'
TimeAgo.locale(en);

class PostTime extends React.Component {

  render () {
    return <ReactTimeAgo locale="en" timeStyle="twitter" tick={true}>{Date.parse(this.props.date)}</ReactTimeAgo>;
  }

}

export default PostTime
