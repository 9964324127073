import React, { useState, useRef, useEffect } from 'react'
import ContentEditable from './ContentEditable'
import './Poll.scss'

const PollForm = (props) => {
  const { updatePost } = props
  const [optionText, setOptionText] = useState('')
  const [options, setOptions] = useState([])
  const [currentOption, setCurrentOption] = useState()

  const newOptionRef = useRef(null)

  const handleBlur = () => setOption()

  const handleKeyPress = (event) => { if (event.key === 'Enter') setOption() }

  const setOption = () => {
    if (optionText.length === 0) return false
    const newOptions = [...options]
    newOptions[currentOption] = optionText
    setOptions(newOptions)
    setOptionText('')
    updatePost(newOptions)
    setCurrentOption(null)
  }

  useEffect(() => focusOnLastOption(), [JSON.stringify(options)])

  const deleteOption = (index) => {
    const currentOptions = [...options]
    currentOptions.splice(index, 1)
    setOptions(currentOptions)
  }

  const focusOnLastOption = () => {
    if (options.length > 0 && newOptionRef && newOptionRef.current && newOptionRef.current.firstChild) {
      newOptionRef.current.firstChild.focus()
      setCurrentOption(options.length)
    }
  }

  const handleChange = (_, value) => setOptionText(value.replace(/\n/g, ''))

  const renderContentEditable = (index, text = '') => {
    const className = index === currentOption && 'pl-4 pr-2 py-2 bg-light' || 'bg-light py-2 px-4'

    return <>
      <ContentEditable
        className={className}
        contentEditable='plaintext-only'
        html={text}
        key={index}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={(e) => e.stopPropagation()}
        onFocus={() => setCurrentOption(index)}
        onKeyPress={handleKeyPress}
        placeholder="Enter in an option"
        tagName="p"
      />
      {index !== currentOption && text !== '' &&
        <span onClick={() => deleteOption(index)} className="top-right py-2 px-4"><i className="fas fa-times" /></span>
      }
    </>
  }

  return (<ul onClick={focusOnLastOption} className="new-poll">
    {options.map((text, index) => <li className="position-relative" key={index}>
      {renderContentEditable(index, text)}
    </li>)}
    <li ref={newOptionRef}>{renderContentEditable(options.length, '')}</li>
    {<li className="my-1" onClick={focusOnLastOption}>
      <button className="btn btn-secondary" onClick={focusOnLastOption}>Add Option</button> or press <strong>Enter</strong>
    </li>}
  </ul>)
}

export default PollForm
