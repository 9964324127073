import React, { Component } from 'react';
import ReactDOM from "react-dom";
import request from 'superagent';
import'./Tribute.scss'
import { emojiIndex } from 'emoji-mart'

const DEFAULT_EMOJI = [
  '+1',
  'grinning',
  'kissing_heart',
  'heart_eyes',
  'laughing',
  'stuck_out_tongue_winking_eye',
  'sweat_smile',
  'joy',
  'scream',
  'disappointed',
  'unamused',
  'weary',
  'sob',
  'sunglasses',
  'heart',
  'poop',
]

export default class ContentEditable extends Component {
  constructor (props) {
    super(props);
    this.state = {active: false};
    this._onChange  = this._onChange.bind(this);
    this._onPaste   = this._onPaste.bind(this);
    this._onFocus   = this._onFocus.bind(this);
    this._onBlur   = this._onBlur.bind(this);
    this._onKeyPress = this._onKeyPress.bind(this);
  }

  remoteTagSearch(text, callback) {
    const req = request.get("/tags.json");
    req.query({ q: text })
    req.end((err, res) => {
      if (res.ok) {
        callback(res.body);
      } else {
        callback([]);
      }
    });
  }

  remoteUserSearch(text, callback) {
    const req = request.get("/users.json");
    req.query({ q: text })
    req.end((err, res) => {
      if (res.ok) {
        callback(res.body);
      } else {
        callback([]);
      }
    });
  }

  componentDidMount() {
    const domNode = ReactDOM.findDOMNode(this);
    const Tribute = require("tributejs");

    const mentions = {
      trigger: "@",
      noMatchTemplate: null,
      values: (text, cb) => {
        this.remoteUserSearch(text, tags => cb(tags));
      },
      lookup: function (user) {
        return  "@" + user.username + " - "+ user.name;
      },
      selectTemplate: function (item) {
        return '<span class="badge badge-secondary" contenteditable="false">@' + item.original.username + '</span>&nbsp;';
      },
      fillAttr: 'username'
    };

    const hashtags = {
      trigger: "#",
      noMatchTemplate: null,
      values: (text, cb) => {
        this.remoteTagSearch(text, tags => cb(tags));
      },
      selectTemplate: function (item) {
        return '<span contenteditable="false">' + item.original.display_text + '</span>&nbsp;';
      },
      lookup: 'display_text_without_hashtag',
      fillAttr: 'display_text_without_hashtag'
    };

    const emoji = {
      trigger: ":",
      values: (text, cb) => {
        if (text.length > 0) {
          cb(emojiIndex.search(text).map((o) => o));
        } else {
          let frequently = []
          for (var i = 0; i < DEFAULT_EMOJI.length; i++) {
            frequently.push(emojiIndex.search(DEFAULT_EMOJI[i])[0])
          }
          cb(frequently);
        }
      },
      noMatchTemplate: null,
      menuItemTemplate: function (item) {
        return `${item.original.native} ${item.original.name}`;
      },
      selectTemplate: function (item) {
        return item.original.native;
      },
      lookup: 'colons'
    }

    this.tribute = new Tribute({
      collection: [hashtags, mentions, emoji]
    });
    this.tribute.replaceTextSuffix = "";
    this.tribute.attach(domNode);

    domNode.addEventListener('tribute-replaced', this._onChange.bind(this));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tagName != this.props.tagName) {
      const domNode = ReactDOM.findDOMNode(this);
      this.tribute.attach(domNode);
      domNode.addEventListener('tribute-replaced', this._onChange.bind(this));
    }
  }

  _onChange(ev) {
    const method  = this.getInnerMethod();
    const value   = this.elem[method];
    this.props.onChange(ev, value);
  }

  _onFocus(ev) {
    this.setState({active: true});
    if (this.props.onFocus) {
      this.props.onFocus(ev);
    }
    return true;
  }

  _onBlur(ev) {
    this.setState({active: false}, this.forceUpdate())
    if (this.props.onBlur) {
      this.props.onBlur(ev);
    }
    return true;
  }

  _onKeyPress(ev) {
    if (this.props.onKeyPress) {
      return this.props.onKeyPress(ev);
    } else {
      return true;
    }
  }

  _onPaste(ev) {
    const { onPaste, contentEditable } = this.props;

    if (contentEditable === 'plaintext-only') {
      ev.preventDefault();
      var text = ev.clipboardData.getData("text");
      document.execCommand('insertText', false, text);
    }

    if (onPaste) {
      onPaste(ev);
    }
  }

  getInnerMethod () {
    return this.props.contentEditable === 'plaintext-only' ? 'innerText' : 'innerHTML';
  }

  shouldComponentUpdate(nextProps, nextState) {
    const method = this.getInnerMethod();
    return (
      nextProps.html !== this.elem[method]
      || this.props.placeholder !== undefined && this.elem[method].trim().length <= 0
      || this.props.className !== nextProps.className
      || this.props.tagName !== nextProps.tagName
    )
  }

  render () {
    const { className, tagName, html, contentEditable, placeholder, ...props } = this.props;
    const Element = tagName || "div";

    if (placeholder !== undefined && html.trim().length <= 0 && !this.state.active) {
      this.html = `<span class='text-muted' style='pointer-events: none;'>${placeholder}</span>`;
    } else {
      this.html = html;
    }

    return (
      <Element
        {...props}
        className={className + (this.state.active ? ' content-editable-active' : '')}
        ref={(elem) => { this.elem = elem }}
        key={this.props.html.trim().length}
        dangerouslySetInnerHTML={{ __html: this.html }}
        contentEditable={ contentEditable === 'false' ? false : true }
        onInput={ this._onChange }
        onKeyPress={ this._onKeyPress }
        onPaste={ this._onPaste }
        onFocus={ this._onFocus }
        onBlur={ this._onBlur }
      />
    )
  }
}
