import React from "react"
import PropTypes from "prop-types"

class PostTypes extends React.Component {
  constructor(props) {
    super(props);
    this.icons = {
      light: "post-type-square border border-dark rounded",
      primary: "post-type-square bg-primary rounded",
      secondary: "post-type-square bg-secondary rounded",
      tertiary: "post-type-square bg-tertiary rounded",
      success: "post-type-square bg-success rounded",
      warning: "post-type-square bg-warning rounded",
      danger: "post-type-square bg-danger rounded",
      dark: "post-type-square bg-dark rounded",
      medium: "post-type-square bg-medium rounded",
      poll: "post-type-square rounded border border-dark fas fa-poll-h d-flex colour-pink"
    }
  }

  renderLi(type, index) {
    return <li key={index} className={"list-inline-item " + (this.props.type == type ? 'active-type' : "")}><button onClick={this.handleClick.bind(this)} value={type} className="btn btn-link btn-sm p-0"><div className={this.icons[type]}></div></button></li>;
  }

  handleClick(e) {
    let value = (e.currentTarget.value && e.currentTarget.value.length > 0 ? e.currentTarget.value : null);
    this.props.onChange(value);
  }

  render () {
    return (
      <ul className="list-inline mb-0">
        {Object.keys(this.icons).map(function (type, index) {
          return this.renderLi(type, index);
        }.bind(this))}
      </ul>
    )
  }
}

PostTypes.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func
};

export default PostTypes
