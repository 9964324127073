import React from "react"
import Dropzone from 'react-dropzone'
import request from 'superagent'

class ProfileBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      uploading: false,
      profileBannerUrl: props.image_url,
      isCurrentUser: false
    };
  }

  componentDidMount() {
    this.setState({ isCurrentUser: this.props.uid == window.UID });
  }

  componentDidUpdate(prevProps) {
    const {profileBannerUrl} = this.props
    if (prevProps.profileBannerUrl != profileBannerUrl)
      this.setState({ profileBannerUrl: profileBannerUrl });
  }

  onDrop(files) {
    this.setState({ edit: false, uploading: true });

    const req = request.post(this.props.update_url || "/users/banner");
    req.set(
      "X-CSRF-Token",
      document.querySelector('meta[name="csrf-token"]').content
    );
    files.forEach(file => {
      req.attach("file", file);
    });
    req.end((err, res) => {
      if (res.ok) {
        this.setState({
          profileBannerUrl: res.body.profile_banner_url,
          edit: false,
          uploading: false
        });
      } else {
        alert(err.message);
      }
    });
  }

  handleEditClick() {
    this.setState({ edit: !this.state.edit, uploading: false });
  }

  render() {
    let image, imageStyle;

    if (this.state.edit) {
      image = (
        <Dropzone
          accept="image/*"
          onDrop={this.onDrop.bind(this)}
          multiple={false}
          style={{
            height: 270,
            borderWidth: 3,
            borderColor: "#fff",
            borderStyle: "dashed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <p className="text-center mb-0 text-white">
            Drag an here image, or click here to select image from your devise
          </p>
        </Dropzone>
      );
    } else if (this.state.uploading) {
      image = (
        <Dropzone
          disabled={true}
          style={{
            height: 270,
            borderWidth: 3,
            borderColor: "#fff",
            borderStyle: "dashed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <p className="text-center mb-0">Uploading...</p>
        </Dropzone>
      );
    } else {
      if (
        this.state.profileBannerUrl &&
        this.state.profileBannerUrl.length > 0
      ) {
        // image = <img className="img-fluid" src={this.state.profileBannerUrl} />
        imageStyle = { backgroundImage: `url(${this.state.profileBannerUrl})` };
      } else {
        image = null;
      }
    }

    let editButton;
    if (this.state.edit && this.props.editable) {
      editButton = (
        <button
          onClick={this.handleEditClick.bind(this)}
          className="btn btn-primary btn-sm"
        >
          <i className="fas fa-times-circle"></i>
        </button>
      );
    } else if (this.props.editable) {
      editButton = (
        <button
          onClick={this.handleEditClick.bind(this)}
          className="btn btn-primary btn-sm"
        >
          <i className="fas fa-cog"></i>
        </button>
      );
    }

    let className = "";
    if (this.state.isCurrentUser) {
      className = this.props.className + " profile-top-image profile-banner";
    } else {
      className =
        this.props.className +
        " profile-top-image profile-banner profile-top-image-" +
        this.props.colour;
    }

    return (
      <div className={className} style={imageStyle}>
        {image}
        {editButton}
      </div>
    );
  }
}

export default ProfileBanner
