import React from "react"
import ReactDOM from "react-dom";
import request from 'superagent';

class Search extends React.Component {

  constructor(props) {
    super(props);
    this.state = {open: false, focused: false, tags: props.tags, value: props.value || ''}
    this.inputSearch = null
  }

  remoteTagSearch(text, callback) {
    const req = request.get("/tags.json");
    req.query({ q: text })
    req.end((err, res) => {
      if (res && res.ok) {
        callback(res.body);
      } else {
        callback([]);
      }
    });
  }

  componentCache() {
    $('#top, #search-spacer').removeClass('search-open');
  }

  componentDidMount() {
    $(document).on("turbolinks:before-cache", this.componentCache);
  }

  componentWillUnmount() {
    $(document).off("turbolinks:before-cache", this.componentCache);
  }

  onFocus() {
    this.setState({open: true, focused: true})
    $('#top, #search-spacer').addClass('search-open')
  }

  handleChange = (e) => this.setState({ value: e.target.value})

  handleKeyDown = (e) => {
    if (e.target.value.length > 0 && /\S/.test(e.target.value)) {
      this.remoteTagSearch(e.target.value, (tags) => this.setState({tags: tags}) )
    } else {
      this.setState({tags: this.props.tags})
    }
  }

  onBlur() {
    this.setState({focused: false})
  }

  close(e) {
    this.setState({open: false})
    ReactDOM.findDOMNode(this.inputSearch).blur();
    $('#top, #search-spacer').removeClass('search-open');
  }

  renderTag(tag, index) {
    return <li key={index} className="list-inline-item mb-3"><a className="btn btn-light btn-lg border-0" href={`/?tag_id=${tag.id}`} role="button">{tag.display_text}</a></li>
  }
  render () {
    let underlay;
    let clearButton;
    let message;

    if (this.state.open) {
      underlay = <div onClick={this.close.bind(this)} className='underlay'></div>;
    }

    if ((this.props.value && this.props.value.length > 0) || (this.props.placeholder && this.props.placeholder.length > 0)) {
      clearButton = <a className='clear-search' href="/"><i className="fas fa-times-hexagon"></i></a>;
    }

    if (this.state.tags.length == 0) {
      message = <h4 className='text-center mt-5'>There are no #hashtags found, but you can still search for <u>{this.state.value}</u></h4>;
    }
    return (
      <div>
        <div className={this.state.open ? 'search-open' : null} id="search">
          <div className="container p-3">
            <div className="row">
              <div className="col-12 d-flex justify-content-center w-100">
                <form>
                  <input ref={(el) => { this.inputSearch = el }} name="q" defaultValue={this.props.value || ''} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} onKeyDown={this.handleKeyDown} onChange={this.handleChange} className="form-control form-control-lg" autoComplete="off" placeholder={this.props.placeholder || "Search"} type="search" />
                  {clearButton}
              </form>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className={this.state.open ? 'col-11 col-sm-10 col-md-8 col-lg-6 d-flex justify-content-center search-tags mt-5' : 'd-none'}>
                <ul className="list-inline">
                  {this.state.tags.map(function (tag, index) {
                    return this.renderTag(tag, index);
                  }.bind(this))}
                </ul>
                {message}
              </div>
            </div>
          </div>
        </div>
      {underlay}
      <div id='search-spacer'></div>
      </div>
    );
  }
}

export default Search
