import React from "react"

class PostEdit extends React.Component {

  constructor (props) {
    super(props)
    this.state = { canEdit: false}
  }

  componentDidMount() {
    this.setState({canEdit: (this.props.uid == window.UID)});
  }

  render () {
    let editLink = null;

    if (this.state.canEdit) {
      editLink = <a className="btn btn-link p-0 mr-2" href={this.props.edit_url}>Edit</a>
    }

    return editLink;
  }

}

export default PostEdit
