import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import request from 'superagent'
import CommentList from './Comments/CommentList'

class Comments extends React.Component {

  constructor(props) {
    super(props);
    this.state = { count: props.comments.length, comments: props.comments }
  }

  submitForm(body) {
    const req = request.post(`/posts/${this.props.commentable_id}/comments.json`);
    req.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)
    req.set('accept', 'json')
    req.send({ body: body })
    req.end((err, res) => {
      if (res.ok) {
        this.setState({count:res.body.comments.length, comments: res.body.comments});
        clearTimeout(this.latestTimer);
        this.latestTimer = setTimeout(this.getLatestComments.bind(this), 4000);
      } else {
        alert(err.message);
      }
    });
  }

  getLatestComments() {
    const req = request.get(`/posts/${this.props.commentable_id}/comments.json`);
    req.end((err, res) => {
      if (res.ok) {
        this.setState({count:res.body.comments.length, comments: res.body.comments});
        clearTimeout(this.latestTimer);
        this.latestTimer = setTimeout(this.getLatestComments.bind(this), 4000);
      } else {
        alert(err.message);
      }
    });
  }

  loadMoreCallback() {
    clearTimeout(this.latestTimer);
    this.latestTimer = setTimeout(this.getLatestComments.bind(this), 4000);
  }

  render () {
    return (
      <div>
        <hr className="mt-0"/>
        <div className="mx-3">
          <CommentList {...this.props} submitForm={this.submitForm.bind(this)} comments={this.state.comments} loadMoreCallback={this.loadMoreCallback.bind(this)}/>
        </div>
      </div>
    );
  }
}

Comments.propTypes = {
  commentable_type: PropTypes.string,
  commentable_id: PropTypes.string,
  count: PropTypes.number,
  comments: PropTypes.array
};

export default Comments
