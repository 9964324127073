import React from "react"
import request from 'superagent'

class Like extends React.Component {

  constructor (props) {
    super(props)
    // link_url
    // unlike_url
    this.state = { liked: false, likes: props.likes}
  }

  componentDidMount() {
    this.setState({liked: this.props.likes.indexOf(window.UID) > -1})
  }

  toggleLike(body) {
    const url = this.state.liked ? this.props.unlike_url : this.props.like_url;
    const req = request.get(url);
    req.set('accept', 'json')
    req.end((err, res) => {
      if (res.ok) {
        this.setState({liked: !this.state.liked, likes: res.body.likes });
      } else {
        alert(err.message);
      }
    });
  }

  render () {
    let iClassName = "fal fa-heart";

    if (this.state.liked) {
      iClassName = "fas fa-heart"
    }

    iClassName += this.props.size == "lg" ? " fa-lg" : ""

    let likes_count;
    if (this.state.likes && this.state.likes.length > 0) {
      likes_count = this.state.likes.length + ' like'
      if (this.state.likes.length > 1) {
        likes_count += 's'
      }
    }

    return (
      <span className="likes float-right">
        <small className="text-muted pr-1">{likes_count}</small>
        <button className="btn btn-link p-0" onClick={this.toggleLike.bind(this)}><i className={iClassName}></i></button>
      </span>
    )
  }

}

export default Like
