import React, { useState } from 'react'

const PollOption = (props) => {
  const { onClick, option, index, previousVote, message, value, ...attr } = props
  const [hoverIndex, setHoverIndex] = useState(null)

  const handleClick = () => { if (onClick) onClick(option.id) }

  const infoMessage = () => {
    const theMessage = message || 'You have already voted. Selecting another option will change your vote'
    return <div className="info-message"><span className="info-inner">{theMessage}</span><span className="info-arrow"></span></div>
  }

  return (
    <li onClick={handleClick} onMouseOver={() => setHoverIndex(index)} onMouseOut={() => setHoverIndex(null)} {...attr} >
      {!option.voted && previousVote.id && (hoverIndex == index) && infoMessage()}
      <div style={{ width: value }} className={`my-2 py-3 text-nowrap ${option.voted ? 'poll-option-voted' : 'poll-option'}`}>
        <span className="px-3">{value}</span> {option.text}
      </div>
    </li>
  )
}

export default PollOption
