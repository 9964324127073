import React from "react"
import PropTypes from "prop-types"
import Comment from './Comment'
import CommentForm from './CommentForm'

class CommentList extends React.Component {

  constructor(props) {
    super(props);

    let comments = [];
    if (props.expand_comments != true & props.comments.length > 1) {
      comments = [props.comments[0]]
    } else {
      comments = props.comments;
    }

    this.state = {count: props.comments.length, comments: comments}
  }

  componentDidUpdate(prevProps) {
    const { comments } = this.props
    if (prevProps.comments.length != comments.length)
      this.setState({
        count: comments.length,
        comments: comments
      });
  }

  renderComment(comment, index) {
    return <li key={index} ><Comment {...comment} /></li>;
  }

  handleMoreClick() {
    this.props.loadMoreCallback()
    this.setState({comments: this.props.comments})
  }

  render () {
    let loadMoreButton;
    if (this.state.count > this.state.comments.length) {
      loadMoreButton = (
        <li>
          <div className="text-center">
            <button className="btn btn-link card-link mb-2 text-muted" onClick={this.handleMoreClick.bind(this)}>
              View {this.state.count - this.state.comments.length} more comment
            </button>
          </div>
        </li>
      );
    } else {
      loadMoreButton = (
        <li>
          <CommentForm {...this.props} />
        </li>
      );
    }

    return (
      <ul className='list-unstyled mb-0'>
        {this.state.comments.map(function (comment, index) {
          return this.renderComment(comment, index);
        }.bind(this))}
        {loadMoreButton}
      </ul>
    );
  }
}

CommentList.propTypes = {
  comments: PropTypes.array
};


export default CommentList
