import React from "react"
import request from 'superagent'

class LatestPosts extends React.Component {

  constructor (props) {
    super(props)
    this.state = { count: 0}
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const req = request.get(`/posts/count.json`);
      req.query({ date: this.props.date })
      req.end((err, res) => {
        if (res.ok) {
          this.setState({count: res.body.count})
        } else {
          // alert(err.message);
        }
      });
    }, 15000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render () {
    let newPosts = null;

    if (this.state.count > 1) {
      newPosts = <div className="alert alert-info text-center"><a href="/" className="alert-link" >There are {this.state.count} new posts</a></div>;
    } else if (this.state.count > 0) {
      newPosts = <div className="alert alert-info text-center"><a href="/" className="alert-link" >There is 1 new post</a></div>;
    }

    return newPosts;
  }

}

export default LatestPosts
