import React from "react"
import ReactDOM from "react-dom"
import InfiniteScroll from "infinite-scroll"
import "./InfinityScroll.scss"

class InfinityScroll extends React.Component {

  constructor (props) {
    super(props)
    this.state = {dontRender: false}
  }

  appendComponents(items) {
    var ujs = ReactRailsUJS
    var nodes = $(items).find('[' + ReactRailsUJS.CLASS_NAME_ATTR + ']');

    for (var i = 0; i < nodes.length; ++i) {
      var node = nodes[i];
      var className = node.getAttribute(ujs.CLASS_NAME_ATTR);
      var constructor = ujs.getConstructor(className);
      var propsJson = node.getAttribute(ujs.PROPS_ATTR);
      var props = propsJson && JSON.parse(propsJson);
      var hydrate = node.getAttribute(ujs.RENDER_ATTR);

      if (!constructor) {
        var message = "Cannot find component: '" + className + "'"
        if (console && console.log) {
          console.log("%c[react-rails] %c" + message + " for element", "font-weight: bold", "", node)
        }
        throw new Error(message + ". Make sure your component is available to render.")
      } else {
        if (hydrate && typeof ReactDOM.hydrate === "function") {
          ReactDOM.hydrate(React.createElement(constructor, props), node);
        } else {
          ReactDOM.render(React.createElement(constructor, props), node);
        }
      }
    }
  }

  componentCache() {
    $(".scroller-item:gt(9)").remove();
    $(".scroller-status").hide()
  }

  componentDidMount() {
    $(document).on("turbolinks:before-cache", this.componentCache);
    const containerElem = document.querySelector('.scroller-container');

    if (document.querySelector('.pagination__next')) {
      this.infScroll = new InfiniteScroll(containerElem, {
        path: '.pagination__next',
        append: '.scroller-item',
        status: '.scroller-status',
        hideNav: '.pagination',
        history: false
      });

      this.infScroll.on( 'append', (response, path, items ) => {
        this.appendComponents(items);
      });
    } else {
      this.setState({dontRender: true})
    }
  }

  componentWillUnmount() {
    $(document).off("turbolinks:before-cache", this.componentCache);
  }

  render () {
    if (this.state.dontRender) {
      return null;
    } else {
      return (
        <div className="scroller-status text-center">
          <p className="infinite-scroll-request m-4"><i className="fas fa-spinner-third fa-3x post-saving-spin"></i></p>
          <h3 className="infinite-scroll-last m-4"> No more :(</h3>
          <h3 className="infinite-scroll-error m-4">Error! Can't load more :(</h3>
        </div>
      );
    }
  }
}

export default InfinityScroll
