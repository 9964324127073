import React from "react"
import request from 'superagent'

class Follow extends React.Component {

  constructor (props) {
    super(props)
    this.state = { following: false}
  }

  componentDidMount() {
    this.setState({following: window.FOLLOWING_IDS.indexOf(this.props.id) > -1})
  }

  toggleFollow(body) {
    const url = this.state.following ? this.props.unfollow_url : this.props.follow_url;
    const req = request.get(url);
    req.set('accept', 'json')
    req.end((err, res) => {
      if (res.ok) {
        console.log(res.body.status);
        this.setState({following: !this.state.following });
      } else {
        alert(err.message);
      }
    });
  }

  render () {
    let text = "Follow"
    let iClassName = "fal fa-plus-circle";

    if (this.state.following) {
      iClassName = "fal fa-check-circle";
      text = "Following";
    }
    return (
      <button onClick={this.toggleFollow.bind(this)} className="btn btn-primary btn-sm">
        <i className={iClassName}></i> {text}
      </button>
    )
  }

}

export default Follow
