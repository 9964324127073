/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)


componentCache = ()=> {
  $('#top [data-react-class="Avatar"]').html(
    $('#top [data-react-class="Avatar"]').html()
  );
}

// https: //github.com/turbolinks/turbolinks/issues/499#issuecomment-547363477
document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll("[data-turbolinks-permanent-delay]").forEach(el => {
    el.setAttribute("data-turbolinks-permanent", "")
    el.removeAttribute("data-turbolinks-permanent-delay")
  })
})

$(document).on("turbolinks:before-cache", componentCache);
