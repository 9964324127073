import React from "react"
import ReactDOM from "react-dom"
import Avatar from '../Avatar'
import ContentEditable from '../ContentEditable'

class CommentForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {body: props.body || ""}
  }

  componentDidMount() {
    this.setState({uid: window.UID, username: window.USERNAME, name: window.NAME, email: window.EMAIL, avatarUrl: window.AVATAR_URL})
  }

  submitForm(value) {
    this.setState({body: ''});
    ReactDOM.findDOMNode(this.contentEditable).blur();
    this.props.submitForm(value);
  }

  handleChange(e, value) {
    this.setState({body: value});
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submitForm(this.state.body);
      return false;
    } else {
      return true;
    }
  }

  handleClick(e) {
    this.submitForm(this.state.body);
  }

  render () {
    return (
      <div className="media my-2 mb-3">
        <Avatar uid={this.state.uid} username={this.state.username} className="mr-2 mt-1" name={this.state.name} size={30} email={this.state.email} avatarUrl={this.state.avatarUrl} />
        <div className="media-body">
          <div className="d-flex justify-content-between align-items-center">
            <ContentEditable ref={(elem) => { this.contentEditable = elem }} html={this.state.body} tagName='p' className="rounded  border py-2 px-3 mb-0 w-100 mr-2" onChange={this.handleChange.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} placeholder="Write a comment" contentEditable='plaintext-only'/>
            <button onClick={this.handleClick.bind(this)} className="btn btn-outline-primary"><i className="fal fa-paper-plane"/></button>
          </div>
        </div>
      </div>
    );
  }
}

export default CommentForm
