import React, { useState } from 'react'
import request from 'superagent'
import './Poll.scss'
import PollOption from './PollOption';

const Poll = (props) => {
  const { vote_percentages } = props
  const [percentages, setPercentages] = useState(vote_percentages)
  const [options, setOptions] = useState(props.options)
  const [isVoting, setIsVoting] = useState(false) // prevents errors from rapid clicking

  const findPreviousVote = () => Object.values({ ...options }).filter((option) => option.voted).pop() || {}
  const previousVote = findPreviousVote()

  const handleClick = (id) => {
    const option = options[id]
    const url = !option.voted ? option.vote_url : option.unvote_url

    // if the user has already voted, remove their previous voted then apply their new vote
    if (previousVote.id && id !== previousVote.id) return handleRequest(previousVote.unvote_url, handleRequest(url))

    handleRequest(url)
  }

  const handleRequest = (url, callback = null) => {
    setIsVoting(true)
    const req = request.get(url)

    req.set('accept', 'json')
    req.end((err, res) => {
      if (!res.ok) return console.error(err)

      setPercentages(res.body.vote_percentages)
      setOptions(res.body.options)
      setIsVoting(false)
      if (callback) callback()
    })
  }

  return (
    <ul className="poll-options">
      {Object.values(options).map((option, index) => (
        <PollOption
          id={option.id}
          index={index}
          key={option.id}
          onClick={!isVoting ? handleClick : null}
          option={option}
          previousVote={previousVote}
          value={`${Math.round(percentages[option.id] * 100)}%`}
        />
      ))}
    </ul>
  )
}

export default Poll
