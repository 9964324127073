import React from "react"
import request from 'superagent'

class UrlPreview extends React.Component {

  constructor (props) {
    super(props)
    this.state = { previewed: false }
  }

  componentDidMount() {
    const req = request.get(`/preview.json`);
    req.query({ url: this.props.url })
    req.end((err, res) => {
      if (res.ok) {
        this.setState({html:res.body.html, embedded: res.body.embedded, previewed: true});
      } else {
        console.console.log(err.message);
      }
    });
  }

  componentWillUnmount() {
  }

  render () {
    if (this.state.previewed) {
      if (this.state.embedded) {
        return <div className="pb-0"><div className="embed-responsive embed-responsive-16by9" dangerouslySetInnerHTML={{__html: this.state.html}}></div></div>;
      } else {
        return <div className="pb-0"><div dangerouslySetInnerHTML={{__html: this.state.html}}></div></div>;
      }
    } else {
      return (
        <div className="mb-2 text-center">
          <i className="fas fa-spinner-third post-saving-spin"></i>
        </div>
      );
    }
  }

}

export default UrlPreview
